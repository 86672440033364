import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { logError } from "../utils";
import { saveImage, getAllImages, removeImageByKey } from "./indexedDB";
import ApiService from "./apiService";

const ID_TOKEN_KEY_REALTIME_SURVEY_DATA = "real_time_survey_data";
const ID_TOKEN_KEY_ALLSURVEY_DATA = "all_survey_data";
const ID_TOKEN_KEY_REALTIME_SALES_DATA = "real_time_sales_data";
const ID_TOKEN_KEY_ALLSALES_DATA = "all_sales_data";

const OfflineService = {
  initialize() {},

  getItem(tokenKey) {
    return JSON.parse(window.localStorage.getItem(tokenKey));
  },

  saveItem(tokenKey, data) {
    window.localStorage.setItem(tokenKey, data);
  },

  saveImage(files, formBody) {
    // Assuming files is a FileList from the input element
    Array.from(files).forEach(file => {
      const reader = new FileReader();

      reader.onloadend = e => {
        // const base64Image = reader.result;
        const base64Image = e.target.result.split(",")[1];

        // Save the image to IndexedDB
        saveImage(base64Image, formBody)
          .then(() => {
            console.log("Image saved successfully.");
          })
          .catch(error => {
            console.error("Error saving image:", error);
          });
      };

      reader.readAsDataURL(file);
    });
  },

  async checkPendingSurveyData() {
    const pending_data = OfflineService.getItem(
      ID_TOKEN_KEY_REALTIME_SURVEY_DATA
    );
    const images = await getAllImages();
    const pending_all_data = OfflineService.getItem(
      ID_TOKEN_KEY_ALLSURVEY_DATA
    );
    if (
      (pending_data && pending_data.length > 0) ||
      (images && images?.elements && images?.elements.length > 0) ||
      (pending_all_data && pending_all_data.length > 0)
    ) {
      return true;
    }
    return false;
  },

  async checkPendingSalesData() {
    const pending_data = OfflineService.getItem(
      ID_TOKEN_KEY_REALTIME_SALES_DATA
    );
    const pending_all_data = OfflineService.getItem(ID_TOKEN_KEY_ALLSALES_DATA);
    if (
      (pending_data && pending_data.length > 0) ||
      (pending_all_data && pending_all_data.length > 0)
    ) {
      return true;
    }
    return false;
  },

  async saveSales() {
    let pending_data = OfflineService.getItem(ID_TOKEN_KEY_REALTIME_SALES_DATA);
    if (!pending_data) pending_data = [];
    let pending_all_data = OfflineService.getItem(ID_TOKEN_KEY_ALLSALES_DATA);
    if (!pending_all_data) pending_all_data = [];
    console.log("pending -- ", pending_data);
    if (pending_data && pending_data.length > 0) {
      let mapped_data = pending_data;
      mapped_data.map((data, index) => {
        ApiService.postNotAlert(`worksession/sales/realtimesavesales`, {
          formData: data
        })
          .then(response => {
            pending_data = pending_data.filter(el => el !== data);
            OfflineService.replaceOfflineSalesRealTimeData(pending_data);
          })
          .catch(error => {
            console.log("error - ", error);
          });
      });
    }
    console.log("pending_all_data -- ", pending_all_data);
    if (pending_all_data && pending_all_data.length > 0) {
      let mapped_data = pending_all_data;
      mapped_data.map((data, index) => {
        ApiService.postNotAlert(`worksession/sales/savesales`, {
          saveSalesData: data
        })
          .then(response => {
            pending_all_data = pending_all_data.filter(el => el !== data);
            OfflineService.replaceOfflineAllSalesData(pending_all_data);
          })
          .catch(error => {
            console.log("error - ", error);
          });
      });
    }
  },

  async saveAndUploadSurveys() {
    let pending_data = OfflineService.getItem(
      ID_TOKEN_KEY_REALTIME_SURVEY_DATA
    );
    if (!pending_data) pending_data = [];
    let pending_all_data = OfflineService.getItem(ID_TOKEN_KEY_ALLSURVEY_DATA);
    if (!pending_all_data) pending_all_data = [];
    let images = await getAllImages();
    if (images) {
      const { keys, elements } = images;
      for (let i = 0; i < elements.length; i++) {
        const image = elements[i];
        let uploadedPhotos = [];
        let { data, formBody } = image;
        try {
          let response = await ApiService.uploadBase64Image(data, true);
          if (response?.data) {
            let uploadedStatusPushItem = {
              photoId: response?.data.id
            };
            uploadedPhotos.push(uploadedStatusPushItem);
          }
          if (uploadedPhotos.length > 0) {
            formBody["uploadedPhotos"] = uploadedPhotos;
          }
          pending_data.push(formBody);
          let removeKey = keys[i];
          removeImageByKey(removeKey);
        } catch (error) {}
      }
    }

    console.log("pending_data - ", pending_data);
    if (pending_data && pending_data.length > 0) {
      let mapped_data = pending_data;
      mapped_data.map((data, index) => {
        ApiService.postNotAlert(`worksession/realTimeSaveSurveyResponse`, data)
          .then(response => {
            pending_data = pending_data.filter(el => el !== data);
            OfflineService.replaceOfflineRealTimeData(pending_data);
          })
          .catch(error => {
            console.log("error - ", error);
          });
      });
    }
    console.log("pending_all_data - ", pending_all_data);
    if (pending_all_data && pending_all_data.length > 0) {
      let mapped_data = pending_all_data;
      mapped_data.map((data, index) => {
        ApiService.postNotAlert(`worksession/saveSessionSurveyDataV2`, data)
          .then(response => {
            pending_data = pending_data.filter(el => el !== data);
            OfflineService.replaceOfflineAllSurveyData(pending_data);
          })
          .catch(error => {
            console.log("error - ", error);
          });
      });
    }
  },

  saveOfflineRealTimeSalesData(data) {
    let savedData = OfflineService.getItem(ID_TOKEN_KEY_REALTIME_SALES_DATA);
    if (!savedData) savedData = [];
    savedData.push(data);
    OfflineService.saveItem(
      ID_TOKEN_KEY_REALTIME_SALES_DATA,
      JSON.stringify(savedData)
    );
  },

  saveOfflineSalesAllData(data) {
    let savedData = OfflineService.getItem(ID_TOKEN_KEY_ALLSALES_DATA);
    if (!savedData) savedData = [];
    savedData.push(data);
    OfflineService.saveItem(
      ID_TOKEN_KEY_ALLSALES_DATA,
      JSON.stringify(savedData)
    );
  },

  replaceOfflineAllSurveyData(data) {
    OfflineService.saveItem(ID_TOKEN_KEY_ALLSURVEY_DATA, JSON.stringify(data));
  },

  replaceOfflineRealTimeData(data) {
    OfflineService.saveItem(
      ID_TOKEN_KEY_REALTIME_SURVEY_DATA,
      JSON.stringify(data)
    );
  },

  replaceOfflineSalesRealTimeData(data) {
    OfflineService.saveItem(
      ID_TOKEN_KEY_REALTIME_SALES_DATA,
      JSON.stringify(data)
    );
  },

  replaceOfflineAllSalesData(data) {
    OfflineService.saveItem(ID_TOKEN_KEY_ALLSALES_DATA, JSON.stringify(data));
  },

  saveOfflineAllData(data) {
    let savedData = OfflineService.getItem(ID_TOKEN_KEY_ALLSURVEY_DATA);
    if (!savedData) savedData = [];
    savedData.push(data);
    OfflineService.saveItem(
      ID_TOKEN_KEY_ALLSURVEY_DATA,
      JSON.stringify(savedData)
    );
  },

  saveOfflineRealTimeData(data) {
    let savedData = OfflineService.getItem(ID_TOKEN_KEY_REALTIME_SURVEY_DATA);
    if (!savedData) savedData = [];
    savedData.push(data);
    OfflineService.saveItem(
      ID_TOKEN_KEY_REALTIME_SURVEY_DATA,
      JSON.stringify(savedData)
    );
  }
};

export default OfflineService;
