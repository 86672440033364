// indexedDB.js

const DB_NAME = "gennera_indexed_db";
const STORE_NAME = "offline_uploading_images";

export const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1);

    request.onupgradeneeded = event => {
      const db = event.target.result;
      db.createObjectStore(STORE_NAME, { keyPath: "id", autoIncrement: true });
    };

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = error => {
      reject(error);
    };
  });
};

export const saveImage = (imageData, formBody) => {
  return openDatabase().then(db => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);

      const request = store.add({ data: imageData, formBody });

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = error => {
        reject(error);
      };
    });
  });
};

export const getAllImages = () => {
  return openDatabase().then(db => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([STORE_NAME], "readonly");
      const store = transaction.objectStore(STORE_NAME);

      const elements = [];
      const keys = [];

      const request = store.openCursor();

      request.onsuccess = event => {
        // resolve(request.result);
        const cursor = event.target.result;

        if (cursor) {
          // Add the element and key to their respective arrays
          elements.push(cursor.value);
          keys.push(cursor.key);

          // Move to the next item in the store
          cursor.continue();
        } else {
          // No more items in the store, resolve with the elements and keys
          resolve({ elements, keys });
        }
      };

      request.onerror = error => {
        reject(error);
      };
    });
  });
};

export const removeImageByKey = key => {
  return openDatabase().then(db => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);

      const request = store.delete(key);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = error => {
        reject(error);
      };
    });
  });
};
