<template>
  <div>
    <router-view></router-view>
    <v-snackbar
      v-model="messageVisitAlert.isShowSnackBar"
      color="grey lighten-1"
      elevation="24"
      :timeout="-1"
      centered
    >
      <div v-if="messageVisitAlert?.message">
        <div
          class="text--primary"
          v-if="messageVisitAlert?.message?.spv_message"
        >
          <span class="text-bold">SPV Message</span> - <br />
          {{ messageVisitAlert?.message?.spv_message.messageContents }}
        </div>
        <div
          class="text--primary mt-2"
          v-if="messageVisitAlert?.message?.gpv_message"
        >
          <span class="text-bold">GPV Message</span> - <br />
          {{ messageVisitAlert?.message?.gpv_message.messageContents }}
        </div>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          class="text--primary"
          elevation="2"
          outlined
          v-bind="attrs"
          @click="markedAsReadSPVGPVMessage"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import OfflineService from "@/services/offlineService";

export default {
  name: "SessionIndex",
  data() {
    return {
      checkTodayIntervalID: null,
      logRealPathIntervalID: null,
      tickCheckTodayPeriod: 5000,
      tickPeriod: process.env.VUE_APP_LOG_REALPATH_TICK_PERIOD,
      offlineCheckingIntervalID: null,
      tickOfflineCheck: 10000,
      isTestingWithDummy:
        process.env.VUE_APP_LOG_REALPATH_IS_TESTING_WITH_DUMMY,
      dummyPositions: [
        { latitude: 41.540967, longitude: 2.204141 },
        { latitude: 41.529467, longitude: 2.179679 },
        { latitude: 41.504479, longitude: 2.17189 }
      ]
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("myroute", [
      "messageVisitAlert",
      "isOffline",
      "currentPosition",
      // "currentGmapAddress",
      "isEndedSession",
      "currentSession",
      "localCurrentDate"
    ]),
    ...mapGetters("myroute", ["deviceInformation"])
  },
  watch: {
    isOffline(newVal, oldVal) {
      if (!newVal) {
        // SAVE db data into Server
        this.savePendingData();
      }
    },
    currentSession(newVal, oldVal) {
      if (newVal && !this.logRealPathIntervalID && !this.isEndedSession) {
        this.logRealPathIntervalID = setInterval(
          this.logPositionTick,
          this.tickPeriod
        );
      }
    }
  },
  methods: {
    async markedAsReadSPVGPVMessage() {
      this.$store.dispatch("myroute/hideMessageVisitAlert");
    },
    async savePendingData() {
      const isTherePendingSurveys = await OfflineService.checkPendingSurveyData();
      console.log('isTherePendingSurveys - ', isTherePendingSurveys);
      if (isTherePendingSurveys && !this.isOffline) {
        console.log("savePendingData - isTherePendingSurveys", isTherePendingSurveys);
        OfflineService.saveAndUploadSurveys();
      }
      const isTherePendingSales = await OfflineService.checkPendingSalesData();
      if (isTherePendingSales && !this.isOffline) {
        console.log("savePendingData - isTherePendingSales", isTherePendingSales);
        OfflineService.saveSales();
      }
    },
    logPositionTick() {
      if (!this.currentSession) return;
      let position = null;
      if (this.isTestingWithDummy == "true") {
        position = this.dummyPositions[parseInt(Math.random() * 10) % 3];
      } else {
        position = this.currentPosition;
      }
      let formData = {
        currentPosition: position,
        currentSession: this.currentSession,
        // currentGmapAddress: this.currentGmapAddress
      };
      ApiService.postNotAlert(`worksession/geopositioning/savepath`, formData)
        .then(response => {
          // console.log("response - ", response);
          this.$store.dispatch("myroute/setIsOffline", false);
        })
        .catch(error => {
          console.log("error - ", error);
          this.$store.dispatch("myroute/setIsOffline", true);
        });
    },
    async init() {
      await this.$store.dispatch("myroute/getCurrentSession", this.user.id);
      await this.$store.dispatch("myroute/setDeviceInformation");
    },
    setTickCheckToday() {
      this.$store.dispatch("myroute/setLocalCurrentDate");
      if (
        this.currentSession &&
        this.currentSession?.date &&
        this.localCurrentDate !== this.currentSession.date
      ) {
        console.log("IT'S NEW DATE !");
        window.location.href = `/session/dashboard`;
      }
    }
  },
  mounted() {
    this.$store.dispatch("myroute/getCurrentPosition");
    this.init();
    ApiService.post(`worksession/getSPVGPVMessages`)
      .then(data => {
        this.$store.dispatch("myroute/setMessageVisitAlert", {
          isShowSnackBar: false,
          message: data
        });
      })
      .catch(err => {
        console.log("err - ", err);
      });
    if (!this.checkTodayIntervalID) {
      this.checkTodayIntervalID = setInterval(
        this.setTickCheckToday,
        this.tickCheckTodayPeriod
      );
    }
    if (!this.offlineCheckingIntervalID) {
      this.offlineCheckingIntervalID = setInterval(
        this.savePendingData,
        this.tickOfflineCheck
      );
    }
  },
  destroyed() {
    if (this.logRealPathIntervalID !== null) {
      console.log("CLEARED INTERVAL - LOG REAL PATH !");
      clearInterval(this.logRealPathIntervalID);
    }
    if (this.checkTodayIntervalID !== null) {
      console.log("CLEARED INTEVAL - CHECKTODAY");
      clearInterval(this.checkTodayIntervalID);
    }
    if (this.offlineCheckingIntervalID !== null) {
      console.log("CLEARED INTEVAL - offlineCheckingIntervalID");
      clearInterval(this.offlineCheckingIntervalID);
    }
  }
};
</script>

<style></style>
